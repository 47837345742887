import i18n from "src/services/i18n";

export const MENU = [
  //#region Profile
  {
    title: i18n.t("side_bar.profile_management"),
    icon: "briefcase",
    type: "sub",
    active: false,
    children: [
      {
        path: "/profile",
        title: i18n.t("side_bar.profile_detail"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Admins
  // {
  //   title: i18n.t("side_bar.admins"),
  //   icon: "user",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: i18n.t("page_admin_list.page_title"),
  //       path: "/admins",
  //       type: "link",
  //     },
  //   ],
  // },
  //#endregion

  //#region Users
  {
    title: i18n.t("side_bar.user_management"),
    icon: "user",
    type: "sub",
    active: false,
    children: [
      {
        path: "/users",
        title: i18n.t("side_bar.user_list"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Groups
  {
    title: i18n.t("side_bar.group_management"),
    icon: "users",
    type: "sub",
    active: false,
    children: [
      {
        path: "/groups",
        title: i18n.t("side_bar.group_list"),
        type: "link",
      },
      {
        path: "/groups/add",
        title: i18n.t("side_bar.group_add"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Plans
  {
    title: i18n.t("side_bar.plan_management"),
    icon: "book",
    type: "sub",
    active: false,
    children: [
      {
        path: "/plans",
        title: i18n.t("side_bar.plan_detail"),
        type: "link",
      },
      {
        path: "/plans/change",
        title: i18n.t("side_bar.plan_change"),
        type: "link",
      },
      {
        path: "/licenses/change",
        title: i18n.t("side_bar.license_change"),
        type: "link",
      },
      {
        path: "/storages",
        title: i18n.t("side_bar.storage_list"),
        type: "link",
      },
      {
        path: "/plans/stop",
        title: i18n.t("side_bar.request_stop_service"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Payment
  {
    title: i18n.t("side_bar.payment_management"),
    icon: "credit-card",
    type: "sub",
    active: false,
    children: [
      {
        path: "/payments",
        title: i18n.t("side_bar.payment_history"),
        type: "link",
      },
      {
        path: "/card/add",
        title: i18n.t("side_bar.payment_card"),
        type: "link",
      },
    ],
  },
  //#endregion

  //#region Security
  {
    title: i18n.t("side_bar.security_management"),
    icon: "lock",
    type: "link",
    active: false,
    path: "/security",
    // children: [
    //   {
    //     path: "/security",
    //     title: i18n.t("side_bar.security"),
    //     type: "link",
    //   },
    // ],
  },
  //#endregion

  //#region PR Channel
  {
    title: i18n.t("pr_channel"),
    icon: "tv",
    type: "sub",
    active: false,
    children: [
      {
        path: "/pr-channels",
        title: i18n.t("pr_channel_list"),
        type: "link",
      },
      {
        path: "/pr-channels/add",
        title: i18n.t("create_pr_channel"),
        type: "link",
      },
    ],
  },
  //#endregion
  //#region Channels
  // {
  //   title: i18n.t("page_channel_setting.page_title"),
  //   icon: "tv",
  //   type: "link",
  //   active: false,
  //   path: "/channel",
  // },
  //#endregion

  //#region Rooms
  {
    title: i18n.t("side_bar.rooms"),
    icon: "package",
    type: "sub",
    active: false,
    children: [
      {
        path: "/rooms",
        title: i18n.t("page_room_list.page_title"),
        type: "link",
      },
      {
        path: "/rooms/add",
        title: i18n.t("page_room_add.page_title"),
        type: "link",
      },
      // {
      //   path: "/rooms/statistics",
      //   title: i18n.t("side_bar.statistics"),
      //   type: "link",
      // },
    ],
  },
  //#endregion

  //#region Room Items

  {
    title: i18n.t("page_room_items.page_title"),
    icon: "box",
    type: "sub",
    active: false,
    children: [
      {
        path: "/room-items",
        title: i18n.t("page_room_items.page_title"),
        type: "link",
        active: false,
      },
      {
        title: i18n.t("side_bar.items_shopping"),
        type: "link",
        active: false,
        path: "/room-items/shopping",
      },
    ],
  },
  //#endregion

  //#region Permissions
  {
    title: i18n.t("side_bar.operator_management"),
    icon: "user",
    type: "sub",
    active: false,
    children: [
      {
        title: i18n.t("side_bar.operator_group"),
        icon: "file",
        type: "link",
        active: false,
        path: "/operator-group",
      },
      {
        title: i18n.t("side_bar.operator"),
        icon: "file",
        type: "link",
        active: false,
        path: "/operator",
      },
    ],
  },
  //#endregion
];
